import * as React from "react";
import Layout from "../components/Layout/Layout";
import Grid from '@mui/material/Grid';
import { ContactForm } from "../components/ContactForm/ContactForm";

const ContactBasicUsage = (props: { pageContext: any; }) => {
    const { pageContext } = props
  
    var siteJson = pageContext.site;
    if (siteJson == null) {
      siteJson = new Object();
    }
  
    var tenantJson = pageContext.tenant;
    if (tenantJson == null) {
      tenantJson = new Object();
    }
  
    return (

        <Layout
            dynamicMenuItems={[]}
            pageTitle={'Contact'}
            siteHeaderLogoImageUrl={siteJson.site_header_logo_image_url}
            siteTitle={siteJson.site_title}
            tenantId={tenantJson.tenant_id}
        >
            <Grid container spacing={3} alignItems="stretch">
                <Grid item xs={12}>
                <ContactForm tenant={tenantJson}></ContactForm>
                </Grid>
            </Grid>

        </Layout>
    )
}
export default ContactBasicUsage